import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Modal, Button, Form, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import AddNewDrillPipeForm from './addNewDrillPipeForm';

function AddCasingBhaItem({ show, handleClose, bhaType, wellId, wellboreId, wellDesignCaseId, onBhaItemAdded }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [od, setOd] = useState([]);
    const [nomWeights, setNomWeights] = useState([]);
    const dpRange = ['1', '2', '3'];
    const dpClass = ['New', 'Premium', 'C-1', 'C-2', 'C-3'];
    const [casingInfo, setCasingInfo] = useState(null);
    const [casingGrade, setCasingGrade] = useState(null);
    // const [dpConn, setDpConn] = useState(null);
    const [casingConnOd, setCasingConnOd] = useState(null);
    const [casingConnId, setCasingConnId] = useState(null);
    const [casingConnections, setCasingConnections] = useState(null);


    const [formData, setFormData] = useState({
        bha_type: bhaType,
        tool_unique_id: '',
        dp_size_id: '',
        dc_num_id: '',
        hwdp_size_id: '',
        jar_unique_id: '',
        casing_size_id: '',
        casing_size: '',
        casing_wt: '',
        casing_id: '',
        conn_od: '',
        int_diam: '',
        tool_class: '',
        casing_grade: '',
        seal: '', // this is dp_conn
        range_length: '',
        tool_length: ''
        // hint: use table names from dp_library where applicable

    });

    const handleOpenAddDrillPipeModal = () => setShowAddDrillPipeModal(true);
    const handleCloseAddDrillPipeModal = () => setShowAddDrillPipeModal(false);
    const handleNewDrillPipeAdded = () => {
        console.log('New drill pipe added!');
    };
    const [showAddDrillPipeModal, setShowAddDrillPipeModal] = useState(false);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const processOdValues = (odValues) => {
        return odValues.map(od => {
            od = od.toString();
            if (!od.includes('.')) {
                return parseFloat(od).toFixed(1);
            }
            return od;
        });
    };

    useEffect(() => {

        const fetchDrillPipeData = () => {
            axios.get(`${baseURL}/input/casing_library/unique_od`, {
                withCredentials: true
            })
                .then(response => {
                    const processedOdValues = processOdValues(response.data.od_values);
                    setOd(processedOdValues);
                })
                .catch(error => {
                    console.error('Error fetching unique ODs available in db:', error);
                });
        };
        fetchDrillPipeData();
    }, [baseURL]);

    const handleOdChange = (e) => {
        const casingSize = e.target.value;
        setFormData({ ...formData, casing_size: casingSize });

        axios.get(`${baseURL}/input/casing_library/${casingSize}`, {
            withCredentials: true
        })
            .then(response => {
                // Extract and format casing_wt values
                const uniqueNomWeights = [
                    ...new Set(response.data.map(item => parseFloat(item.casing_wt).toFixed(1)))
                ].map(weight => ({ casing_wt: weight })); // Convert back to object format

                console.log('uniqueNomWeights', uniqueNomWeights);
                setNomWeights(uniqueNomWeights);
            })
            .catch(error => {
                console.error('Error fetching casing wt:', error);
            });
    };

    const handleNomWtChange = (e) => {
        const casingNomWt = e.target.value;
        setFormData(prevState => ({ ...prevState, casing_wt: casingNomWt }));

        axios.get(`${baseURL}/input/casing_library/${formData.casing_size}/${casingNomWt}`, {
            withCredentials: true
        })
            .then(response => {
                setCasingInfo(response.data);
                setCasingGrade(response.data.casing_grade);

                // Now fetch the casing connections information
                return axios.get(`${baseURL}/input/casing_connections?casing_size=${formData.casing_size}&casing_wt=${casingNomWt}`, {
                    withCredentials: true
                });
            })
            .then(connectionsResponse => {
                // Handle the response from the casing_connections endpoint
                setCasingConnections(connectionsResponse.data);
                console.log('connectionsResponse', connectionsResponse.data);
            })
            .catch(error => {
                console.error('Error fetching Casing Info or Connections:', error);
            });
    };

    const handleGradeChange = (e) => {
        const casingGrade = e.target.value;
        setFormData(prevState => ({ ...prevState, casing_grade: casingGrade }));

        axios.get(`${baseURL}/input/casing_library/${formData.casing_size}/${formData.casing_wt}/${casingGrade}`, {
            withCredentials: true
        })
            .then(response => {
                setFormData(prevState => ({ ...prevState, casing_size_id: response.data.casing_size_id }));
                console.log('casing_size_id', response.data.casing_size_id);
            })
            .catch(error => {
                console.error('Error fetching DP TJ:', error);
            });
    };

    const handleConnChange = (e) => {
        const casingConn = e.target.value;
        setFormData(prevState => ({ ...prevState, seal: casingConn }));

        axios.get(`${baseURL}/input/casing_connections/${formData.casing_size}/${formData.casing_wt}/${casingConn}`, {
            withCredentials: true
        })
            .then(response => {
                // setFormData(prevState => ({ ...prevState, conn_id: response.data.conn_id }));
                setCasingConnOd(response.data.conn_od);
                setCasingConnId(response.data.int_diam);

            })
            .catch(error => {
                console.error('Error fetching DP TJ:', error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios({
            method: "POST",
            url: `${baseURL}/input/bha_items`,
            withCredentials: true,
            data: {
                well_id: parseFloat(wellId),
                wellbore_id: parseFloat(wellboreId),
                well_design_case_id: parseFloat(wellDesignCaseId),
                bha_type: bhaType,
                tool_unique_id: null,
                dp_size_id: null,
                dc_num_id: null,
                hwdp_size_id: null,
                jar_unique_id: null,
                casing_size_id: parseFloat(formData.casing_size_id),
                casing_size: parseFloat(formData.casing_size),
                casing_wt: parseFloat(formData.casing_wt),
                casing_id: parseFloat(formData.casing_id),
                tool_jt_od: parseFloat(casingConnOd),
                tool_jt_id: parseFloat(casingConnId),
                tool_class: formData.tool_class,
                dp_grade: formData.casing_grade,
                dp_conn: formData.seal,
                range_length: parseFloat(formData.range_length),
                tool_length: parseFloat(formData.tool_length)

                // ...formData
                // hint: use table names from bha_items where applicable

            }
        }).then(response => {
            onBhaItemAdded();
            handleClose();
        }).catch(error => {
            console.error("Error adding BHA item:", error);
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add {bhaType} to the BHA</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="od">
                        <Form.Label column sm={4}>Outer Diameter</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.casing_size} onChange={handleOdChange} required>
                                <option value="">Select OD</option>
                                {od.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="nomWt">
                        <Form.Label column sm={4}>Nominal Weight</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.casing_wt} onChange={handleNomWtChange} required>
                                <option value="">Select Nom. Wt.</option>
                                {nomWeights.map((nomWt, index) => (
                                    <option key={index} value={nomWt.casing_wt}>{nomWt.casing_wt}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpId">
                        <Form.Label column sm={4}>Internal Diameter</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                name="casing_id"
                                value={casingInfo ? casingInfo.casing_id || '' : ''}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpGrade">
                        <Form.Label column sm={4}>Grade</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.casing_grade} onChange={handleGradeChange} required>
                                <option value="">Select Grade</option>
                                {casingGrade && casingGrade.length > 0 && casingGrade.map((grade, index) => (
                                    <option key={index} value={grade}>{grade}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="connection">
                        <Form.Label column sm={4}>Connection</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.seal} onChange={handleConnChange} required>
                                <option value="">Select Connection</option>
                                {casingConnections && casingConnections.length > 0 && casingConnections.map((connection, index) => (
                                    <option key={index} value={connection}>{connection}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="class">
                        <Form.Label column sm={4}>Class</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.tool_class} onChange={(e) => setFormData({ ...formData, tool_class: e.target.value })} required>
                                <option value="">Select Class</option>
                                {dpClass.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpTjOd">
                        <Form.Label column sm={4}>Tool Joint OD</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="dp_tj_od"
                                value={casingConnOd ? casingConnOd || '' : ''}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpTjId">
                        <Form.Label column sm={4}>Tool Joint ID</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="dp_tj_id"
                                value={casingConnId ? casingConnId || '' : ''}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpRange">
                        <Form.Label column sm={4}>Range</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.range_length} onChange={(e) => setFormData({ ...formData, range_length: e.target.value })} required>
                                <option value="">Select Range</option>
                                {dpRange.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="length">
                        <Form.Label column sm={4}>Length</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                // placeholder="Enter length"
                                name="tool_length"
                                value={formData.tool_length}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>

                    {/* Additional form fields based on bhaType */}

                    <Button variant="primary" type="submit">
                        Add
                    </Button>

                    <div className='mt-2'>
                        Can't find the right {bhaType}?{' '}
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="add-to-db-tooltip">Click to add a new BHA item to the database</Tooltip>}
                        >
                            <button type='button' className="text-decoration-none btn btn-link p-0" onClick={handleOpenAddDrillPipeModal}>Add it to the database.</button>
                        </OverlayTrigger>

                        <AddNewDrillPipeForm
                            show={showAddDrillPipeModal}
                            handleClose={handleCloseAddDrillPipeModal}
                            onNewDrillPipeAdded={handleNewDrillPipeAdded}
                        />
                    </div>

                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default AddCasingBhaItem;
