import React, { useState, useContext } from "react";
import { Tabs, Tab, Form, Table, Button, Row, Col } from "react-bootstrap";
// import { GlobalStateContext } from "../../GlobalStateContext";
// import { useRheologyData } from "../../well_information/rheology/RheologyProvider";
import DepthParameterInput from "./Monitoring/DepthParameterInput";
// import MonitoringDataHandler from "./Monitoring/MonitoringDataHandler";
import { GlobalStateContext } from "../../GlobalStateContext";

const DrillingAndFrictionFactors = ({
    parameters,
    frictionFactors,
    selectedFactors,
    handleParameterChange,
    handleFrictionChange,
    handleCheckboxChange,
}) => {
    const [chartData, setChartData] = useState([]);
    // const [passChartData, setPassChartData] = useState([]);
    const { setSelectedItems } = useContext(GlobalStateContext);

    // this handler gets chart data from DepthParameterInput.js
    const handleDataUpdate = (updatedData) => {
        setChartData(updatedData);
        setSelectedItems(prevState => ({
            ...prevState,
            chartDataTnDActual: updatedData
        }))
    };

    // This handler will hold the chartData state and use a function to pass it between components - particularly to DrillingDesignCalculator
    // const handleChartDataUpdate = (data) => {
    //     setPassChartData(data);
    // };

    console.log('chartData', chartData);
    // console.log('passChartData', passChartData);

    const handleSubmit = () => {
        const selectedActivities = Object.keys(selectedFactors).filter(
            (key) => selectedFactors[key]
        );
        console.log("Selected Parameters:", parameters);
        console.log("Selected Friction Factors:", selectedActivities);
        console.log("selectedFactors:", selectedFactors);

        alert("Form submitted successfully!");
    };

    const formatActivityName = (name) =>
        name.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());

    return (
        <>
            <Tabs defaultActiveKey="drillingParameters" className="mb-3">
                {/* Tab 1: Drilling Parameters */}
                <Tab eventKey="drillingParameters" title="Drilling Parameters">
                    <Form className="bg-light">
                        <Row>
                            {Object.keys(parameters).map((param) => (
                                <Col md={6} lg={4} key={param} className="mb-3">
                                    <Form.Group>
                                        <Form.Label>{param.toUpperCase()}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={param}
                                            value={parameters[param]}
                                            onChange={handleParameterChange}
                                            placeholder={`Enter ${param.toUpperCase()}`}
                                            size="sm"
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                    </Form>
                </Tab>

                {/* Tab 2: Friction Factors */}
                <Tab eventKey="frictionFactors" title="Friction Factors">
                    <h5>Friction Factors</h5>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Activity</th>
                                <th>Cased Hole</th>
                                <th>Open Hole</th>
                                <th>Range (+/-FF)</th>
                                <th>Include in Design</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(frictionFactors.casedHole).map((activity) => (
                                <tr key={activity}>
                                    <td>{formatActivityName(activity)}</td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            value={frictionFactors.casedHole[activity]}
                                            onChange={(e) => handleFrictionChange(e, "casedHole", activity)}
                                            size="sm"
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            value={frictionFactors.openHole[activity]}
                                            onChange={(e) => handleFrictionChange(e, "openHole", activity)}
                                            size="sm"
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            value={frictionFactors.range[activity]}
                                            onChange={(e) => handleFrictionChange(e, "range", activity)}
                                            size="sm"
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            type="checkbox"
                                            checked={selectedFactors[activity]}
                                            onChange={(e) => handleCheckboxChange(e, activity)}


                                            disabled={activity === 'trippingIn' || activity === 'trippingOut' || activity === 'rotatingOffBottom'}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Button variant="primary" className="mt-3" onClick={handleSubmit} style={{ width: "145px" }}>
                        Submit
                    </Button>
                </Tab>
                <Tab eventKey="monitoring" title="Monitoring" >
                    <div >
                        <p>
                            Torque and Drag Monitoring
                        </p>
                    </div>
                    <DepthParameterInput onDataUpdate={handleDataUpdate} />
                </Tab>
            </Tabs>
            {/* <MonitoringDataHandler passChartData={passChartData} onDataUpdate={handleChartDataUpdate} /> */}

        </>
    );
};

export default DrillingAndFrictionFactors;
