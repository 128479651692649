import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import DrillingParameterForm from './DrillingParameterForm';
import { GlobalStateContext } from '../../GlobalStateContext';
import { useHoleSection } from '../../HoleSectionProvider';

function DrillingParametersTable() {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const { selectedItems, setSelectedItems } = useContext(GlobalStateContext);
    const [drillingParameters, setDrillingParameters] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentParameter, setCurrentParameter] = useState(null);
    // const openHoleParameters = selectedItems?.openHoleParameters;
    const { selectedHoleItems } = useHoleSection();
    const openHoleMd = selectedHoleItems?.openHoleParameters?.depth_md;

    const fetchDrillingParameters = async () => {
        try {
            const response = await axios.get(`${baseURL}/input/drilling_parameters`, {
                withCredentials: true
            });
            setDrillingParameters(response.data);
            // console.log('setDrillingParameters--', response.data);
            const drillingParameters = response.data;

            // Find the drilling parameters for the current default_parameter_id
            const parameters = drillingParameters.find(item => item.drilling_parameters_id === selectedItems.default_parameter_id);
            if (parameters) {
                // Store the parameters in the global state
                setSelectedItems(prevState => ({
                    ...prevState,
                    drillingParameters: parameters
                }));
                // console.log('drilling parameters', parameters);
            }

        } catch (error) {
            console.error('Error fetching drilling parameters:', error);
        }
    };

    useEffect(() => {

        const fetchDrillingParameters = async () => {
            try {
                const response = await axios.get(`${baseURL}/input/drilling_parameters`, {
                    withCredentials: true
                });
                setDrillingParameters(response.data);
                // console.log('setDrillingParameters--', response.data);
                const drillingParameters = response.data;

                // Find the drilling parameters for the current default_parameter_id
                const parameters = drillingParameters.find(item => item.drilling_parameters_id === selectedItems.default_parameter_id);
                if (parameters) {
                    // Store the parameters in the global state
                    setSelectedItems(prevState => ({
                        ...prevState,
                        drillingParameters: parameters
                    }));
                    // console.log('drilling parameters', parameters);
                }

            } catch (error) {
                console.error('Error fetching drilling parameters:', error);
            }
        };

        fetchDrillingParameters();
    }, [baseURL, selectedItems.default_parameter_id, setSelectedItems ]);

    const handleAddSubmit = async (newParameter) => {
        try {
            await axios.post(`${baseURL}/input/drilling_parameters`, newParameter, {
                withCredentials: true
            });
            fetchDrillingParameters();
            setShowAddModal(false);
        } catch (error) {
            console.error('Error adding drilling parameter:', error);
        }
    };

    const handleEditSubmit = async (updatedParameter) => {
        try {
            await axios.put(`${baseURL}/input/drilling_parameters/${currentParameter.drilling_parameters_id}`, updatedParameter, {
                withCredentials: true
            });
            fetchDrillingParameters();
            setShowEditModal(false);
        } catch (error) {
            console.error('Error editing drilling parameter:', error);
        }
    };

    const handleDeleteSubmit = async () => {
        try {
            await axios.delete(`${baseURL}/input/drilling_parameters/${currentParameter.drilling_parameters_id}`, {
                withCredentials: true
            });
            fetchDrillingParameters();
            setShowDeleteModal(false);
        } catch (error) {
            console.error('Error deleting drilling parameter:', error);
        }
    };

    const handleDefaultChange = (parameterId) => {
        // console.log('Setting default_parameter_id to:', parameterId);
        setSelectedItems((prevState) => {
            const newState = { ...prevState, default_parameter_id: parameterId };
            const parameters = drillingParameters.find(item => item.drilling_parameters_id === selectedItems.default_parameter_id);

            // console.log('New state:', newState);
            console.log('Drilling parameters state:', parameters);

            return newState;
        });
    };

    // ensure that a drilling parameter with the bit depth closest to the open hole depth is automatically checked upon mounting, 
    // if none is already checked

    useEffect(() => {
        const selectClosestParameter = () => {
            if (!selectedItems.default_parameter_id && drillingParameters.length > 0) {
                const closestParameter = drillingParameters
                    .filter(parameter => parameter.string_depth < openHoleMd) // Only consider valid depths
                    .reduce((closest, current) => {
                        const closestDiff = Math.abs(openHoleMd - (closest?.string_depth || 0));
                        const currentDiff = Math.abs(openHoleMd - current.string_depth);
                        return currentDiff < closestDiff ? current : closest;
                    }, null);

                if (closestParameter) {
                    setSelectedItems((prevState) => ({
                        ...prevState,
                        default_parameter_id: closestParameter.drilling_parameters_id,
                    }));
                }
            }
        };

        selectClosestParameter();
    }, [drillingParameters, openHoleMd, selectedItems.default_parameter_id, setSelectedItems]);


    return (
        <div className="container mt-4">
            {/* <p>Depth:{openHoleMd}</p> */}
            <Table striped hover>
                <thead className="border-top border-primary">
                    <tr>
                        <th className="fw-normal">Default</th>
                        <th className="fw-normal">RPM</th>
                        <th className="fw-normal">ROP</th>
                        <th className="fw-normal">Pipe <sub>Ecc</sub></th>
                        <th className="fw-normal">&delta;P<sub>Motor</sub></th>
                        <th className="fw-normal">ECD <sub>Limit</sub></th>
                        <th className="fw-normal">WOB</th>
                        <th className="fw-normal">T<sub>Bit</sub></th>
                        <th className="fw-normal">Depth<sub>Bit</sub></th>
                        <th className="fw-normal">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {drillingParameters
                        .filter(parameter => parameter.string_depth < openHoleMd) // Only include records where bit depth < openHoleMd
                        .map(parameter => (
                            <tr key={parameter.drilling_parameters_id}>
                                <td className="col-1">
                                    <Form.Check
                                        type="radio"
                                        name="defaultParameter"
                                        checked={selectedItems.default_parameter_id === parameter.drilling_parameters_id}
                                        onChange={() => handleDefaultChange(parameter.drilling_parameters_id)}
                                    />
                                </td>
                                <td>{parameter.rpm}</td>
                                <td>{parameter.rop}</td>
                                <td>{parameter.pipe_eccentricity}</td>
                                <td>{parameter.motor_pressure_drop}</td>
                                <td>{parameter.ecd_limit}</td>
                                <td>{parameter.wob}</td>
                                <td>{parameter.torque_at_bit}</td>
                                <td>{parameter.string_depth}</td>
                                <td>
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        className="rounded-circle"
                                        onClick={() => { setCurrentParameter(parameter); setShowEditModal(true); }}
                                    >
                                        <i className="bi bi-pencil"></i>
                                    </Button>{' '}
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        className="rounded-circle"
                                        onClick={() => { setCurrentParameter(parameter); setShowDeleteModal(true); }}
                                    >
                                        <i className="bi bi-trash"></i>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                </tbody>

            </Table>
            <Button variant="primary" onClick={() => setShowAddModal(true)} style={{ marginTop: '20px' }}>Add Drilling Parameters</Button>

            {/* Add Modal */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Drilling Parameter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DrillingParameterForm onSubmit={handleAddSubmit} />
                </Modal.Body>
            </Modal>

            {/* Edit Modal */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Drilling Parameter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DrillingParameterForm parameter={currentParameter} onSubmit={handleEditSubmit} />
                </Modal.Body>
            </Modal>

            {/* Delete Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Drilling Parameter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this drilling parameter?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteSubmit}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default DrillingParametersTable;
