import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Table, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { processOdValue } from '../utils/odValueProcessor';

export default function CasingDimensionsLookup() {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables
    // const { token } = useToken();

    const [casingSizes, setCasingSizes] = useState([]);
    const [nominalWeights, setNominalWeights] = useState([]);
    const [grades, setGrades] = useState([]);
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedWeight, setSelectedWeight] = useState('');
    const [selectedGrade, setSelectedGrade] = useState('');
    const [casingData, setCasingData] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch casing sizes from the API
        axios.get(`${baseURL}/input/casing-library`, {
            withCredentials: true,
        })
            .then((response) => {
                const data = response.data;
                const uniqueSizes = [...new Set(data.map((item) => item.casing_size))];
                setCasingSizes(uniqueSizes);
            })
            .catch((error) => {
                console.error(error); // Log the error for debugging
                setError('Failed to load casing sizes');
            });
    }, [baseURL]); // Include token as a dependency if it may change


    const handleSizeChange = (e) => {
        setSelectedSize(e.target.value);
        setSelectedWeight('');
        setSelectedGrade('');
        setCasingData(null);
        setError('');
        console.log('casingSize', e.target.value);

        // Fetch nominal weights for the selected casing size
        axios.get(`${baseURL}/input/casing-library-by-size?casing_size=${e.target.value}`, {
            withCredentials: true,
        })
            .then((response) => {
                const uniqueWeights = [...new Set(response.data.map((item) => item.casing_wt))];
                const sortedWeights = uniqueWeights.sort((a, b) => a - b); // Sort in ascending order
                setNominalWeights(sortedWeights);

                console.log('sortedWeights', sortedWeights);
            })
            .catch(() => setError('Failed to load nominal weights'));
    };

    const handleWeightChange = (e) => {
        const weight = e.target.value;
        const formattedWeight = processOdValue(weight);
        const formattedSize = processOdValue(selectedSize);


        setSelectedWeight(e.target.value);
        setSelectedGrade('');
        setCasingData(null);
        setError('');

        // Fetch grades for the selected casing size and weight
        // axios.get(`${baseURL}/input/casing-library?casing_size=${selectedSize}&casing_wt=${e.target.value}`, {
        axios.get(`${baseURL}/input/casing-library/${formattedSize}/${formattedWeight}`, {

            withCredentials: true,
        }

        )
            .then((response) => {
                console.log('casing-library axios repsonse', response.data);
                const uniqueGrades = [...new Set(response.data.map((item) => item.casing_grade))];
                const sortedUniqueGrades = uniqueGrades.sort((a, b) => a.localeCompare(b)); // Correct string sorting

                setGrades(sortedUniqueGrades);
            })
            .catch(() => setError('Failed to load grades'));
    };

    const handleGradeChange = (e) => {
        const grade = e.target.value;
        console.log('grade:', grade);

        setSelectedGrade(e.target.value);
        setError('');

        // Fetch casing data for the selected size, weight, and grade
        axios.get(`${baseURL}/input/casing`, {
            params: {
                casing_size: selectedSize,
                casing_wt: selectedWeight,
                casing_grade: grade,
            },
            withCredentials: true,
        })
            .then((response) => {
                if (response.data) {
                    const casingData = response.data;
                    setCasingData(casingData);
                    console.log('casing data', response.data);

                } else {
                    setCasingData(null);
                    setError('No data found for the selected casing parameters.');
                }
            })
            .catch(() => setError('Failed to load casing data'));
    };

    return (
        <Container>
            <h2 className="mt-4 text-start">Casing Dimensions and Strength</h2>

            <Row className="mt-3">
                <Col md={4}>
                    <Form.Group controlId="casingSize" className="mb-3">
                        <Form.Label>Casing Size (inches):</Form.Label>
                        <Form.Control as="select" value={selectedSize} onChange={handleSizeChange}>
                            <option value="">Select Size</option>
                            {casingSizes.map((size) => (
                                <option key={size} value={size}>{size}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group controlId="nominalWeight" className="mb-3">
                        <Form.Label>Nominal Weight (lb/ft):</Form.Label>
                        <Form.Control as="select" value={selectedWeight} onChange={handleWeightChange} disabled={!selectedSize}>
                            <option value="">Select Weight</option>
                            {nominalWeights.map((weight) => (
                                <option key={weight} value={weight}>{weight}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group controlId="casingGrade" className="mb-3">
                        <Form.Label>Casing Grade:</Form.Label>
                        <Form.Control as="select" value={selectedGrade} onChange={handleGradeChange} disabled={!selectedWeight}>
                            <option value="">Select Grade</option>
                            {grades.map((grade) => (
                                <option key={grade} value={grade}>{grade}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>

            {error && (
                <Alert variant="danger" className="mt-3">
                    {error}
                </Alert>
            )}

            {casingData && (
                <Row className="mt-4">
                    <Col>
                        <h3>Casing Properties:</h3>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td>Drift Diameter (inches):</td>
                                    <td>{casingData.casing_drift}</td>
                                </tr>
                                <tr>
                                    <td>Internal Diameter (inches):</td>
                                    <td>{casingData.casing_id}</td>
                                </tr>
                                <tr>
                                    <td>Collapse Pressure (psi):</td>
                                    <td>{casingData.collapse}</td>
                                </tr>
                                <tr>
                                    <td>Internal Yield Pressure PE/Extreme Line (psi):</td>
                                    <td>{casingData.internal_yield_pe}</td>
                                </tr>
                                <tr>
                                    <td>Internal Yield Pressure STC (psi):</td>
                                    <td>{casingData.internal_yield_stc ? casingData.internal_yield_stc : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Internal Yield Pressure LTC (psi):</td>
                                    <td>{casingData.internal_yield_ltc ? casingData.internal_yield_ltc : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Internal Yield Pressure BTC (psi):</td>
                                    <td>{casingData.internal_yield_btc ? casingData.internal_yield_btc : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Body Yield Strength (1000lb):</td>
                                    <td>{casingData.body_yield ? casingData.body_yield : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Joint Yield Strength - STC (1000lb):</td>
                                    <td>{casingData.joint_strength_stc ? casingData.joint_strength_stc : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Joint Yield Strength - LTC (1000lb):</td>
                                    <td>{casingData.joint_strength_ltc ? casingData.joint_strength_ltc : '-'}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}

            <Button variant="secondary" className="mt-4" onClick={() => window.location.reload()}>
                Reset
            </Button>
        </Container>
    );
}
