import React, { useState, useEffect } from 'react';
import { Form, Button, Table, Container, Row, Col, Modal } from 'react-bootstrap';
import axios from 'axios';

function CapacityCalculator() {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [calculationType, setCalculationType] = useState('openHole');
    const [holeDiameter, setHoleDiameter] = useState('');
    const [length, setLength] = useState('');
    const [casingID, setCasingID] = useState('');
    const [tubingOptions, setTubingOptions] = useState([]);
    const [selectedTubing, setSelectedTubing] = useState({ tubing1: '', tubing2: '' });

    const [results, setResults] = useState(null);
    const [unit, setUnit] = useState('Field');
    const lengthUnit = unit === 'Field' ? 'ft' : 'm';
    const diamUnit = unit === 'Field' ? 'in' : 'mm';
    const weightUnit = unit === 'Field' ? 'lb/ft' : 'kg/m';

    const [od, setOd] = useState([]);
    const [nomWeights, setNomWeights] = useState([]);

    const [formData, setFormData] = useState({
        casing_size: '',
        casing_wt: '',

    });

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleClose = () => setShowModal(false);
    const showModalAlert = (message) => {
        setModalMessage(message);
        setShowModal(true);
    };

    const convertToMetric = (value, type) => {
        if (unit === 'Field') return value;
        switch (type) {
            case 'length': return (value * 0.3048).toFixed(2);
            case 'diameter': return (value * 25.4).toFixed(1);
            case 'weight': return (value * 1.488).toFixed(2);
            default: return value;
        }
    };

    // eslint-disable-next-line no-unused-vars
    const convertToField = (value, type) => {
        if (unit === 'Metric') return value;
        switch (type) {
            case 'length': return (value / 0.3048).toFixed(2);
            case 'diameter': return (value / 25.4).toFixed(1);
            case 'weight': return (value / 1.488).toFixed(2);
            default: return value;
        }
    };

    const casingOD = parseFloat(formData.casing_size);

    const processOdValues = (odValues) => {
        return odValues.map(od => {
            od = od.toString();
            if (!od.includes('.')) {
                return parseFloat(od).toFixed(1);
            }
            return od;
        });
    };

    useEffect(() => {
        const fetchCasingData = () => {
            axios.get(`${baseURL}/input/casing_library/unique_od`, {
                withCredentials: true
            })
                .then(response => {
                    const processedOdValues = processOdValues(response.data.od_values);
                    setOd(processedOdValues);
                    setTubingOptions(processedOdValues);
                })
                .catch(error => {
                    console.error('Error fetching unique ODs available in db:', error);
                });
        };

        fetchCasingData();
    }, [baseURL]);

    const handleOdChange = (e) => {
        const casingSize = e.target.value;
        console.log('casingSize', casingSize);
        console.log('units:', unit);
        console.log('units:', e.target.value / 25.4);

        setFormData({ ...formData, casing_size: casingSize });

        axios.get(`${baseURL}/input/casing_library/${casingSize}`, {
            withCredentials: true
        })
            .then(response => {
                // Extract and format casing_wt values
                const uniqueNomWeights = [
                    ...new Set(response.data.map(item => parseFloat(item.casing_wt).toFixed(1)))
                ].map(weight => ({ casing_wt: weight })); // Convert back to object format

                console.log('uniqueNomWeights', uniqueNomWeights);
                setNomWeights(uniqueNomWeights);
            })
            .catch(error => {
                console.error('Error fetching casing wt:', error);
            });
    };

    const handleNomWtChange = (e) => {
        const casingNomWt = e.target.value;
        setFormData(prevState => ({ ...prevState, casing_wt: casingNomWt }));

        axios.get(`${baseURL}/input/casing_library/${formData.casing_size}/${casingNomWt}`, {
            withCredentials: true
        })
            .then(response => {
                setCasingID(response.data.casing_id);
            })

            .catch(error => {
                console.error('Error fetching Casing ID:', error);
            });
    };

    const handleCalculationTypeChange = (e) => {
        setCalculationType(e.target.value);
        setResults({
            volumeBbl: '',
            volumeGal: '',
            volumeFt3: '',
            volumeLtr: '',
            volumeM3: '',
            annCapBblPerFt: '',
            annCapGalPerFt: '',
            annCapFtPerGal: '',
            annCapFtPerBbl: '',
            annCapFt3PerFt: '',
            annCapFtPerFt3: '',
        });
    }

    const calculateCapacities = () => {

        let totalLength = parseFloat(length);
        if (unit === 'Metric') totalLength *= 3.281;

        let volume = 0;
        let capacity = 0;
        let tubingTotal = 0;
        let tubinTotalSize = 0;

        // if metric units selected, convert diameter in mm to inches for use in the formula below
        const hole_diam = holeDiameter ? (unit === 'Metric' ? holeDiameter / 25.4 : holeDiameter) : '';

        console.log('hole_diam in inches', hole_diam);
        console.log('unit:', unit);

        switch (calculationType) {
            case 'openHole':
                if (!hole_diam || !length) {
                    // alert('Hole diameter and length are required!');
                    showModalAlert('Hole diameter and length are required!');
                    return;
                }
                volume = (hole_diam ** 2 / 1029.46) * totalLength;
                capacity = volume / totalLength;

                break;
            case 'casing':
                if (!casingID) {
                    // alert('Casing ID is required!');
                    showModalAlert('Casing ID is required!');
                    return;
                }

                if (!length) {
                    // alert('Length is required!');
                    showModalAlert('Length is required!');
                    return;
                }

                volume = (casingID ** 2 / 1029.46) * totalLength;
                capacity = volume / totalLength;

                break;
            case 'annularOpenHoleCasing':
                console.log('casingOD', casingOD);

                if (!hole_diam || !length) {
                    // alert('Hole diameter and length are required!');
                    showModalAlert('Hole diameter and length are required!');
                    return;
                }

                if (!casingOD) {
                    // alert('Casing OD is required!');
                    showModalAlert('Casing OD is required!');
                    return;
                }
                if (casingOD >= hole_diam) {
                    // alert('Casing OD must be less than hole diameter!');
                    showModalAlert('Casing OD must be less than hole diameter!');

                    return;
                }
                volume = ((hole_diam ** 2 - casingOD ** 2) / 1029.46) * totalLength;
                capacity = volume / totalLength;

                break;
            case 'annularCasingTubing':
                if (!casingID || (!selectedTubing.tubing1 && !selectedTubing.tubing2)) {
                    showModalAlert('Casing ID and at least one tubing string are required!');
                    return
                }

                if (!length) {
                    // alert('Length is required!');
                    showModalAlert('Length is required!');
                    return;
                }

                if (selectedTubing.tubing1) tubingTotal += parseFloat(selectedTubing.tubing1) ** 2;
                if (selectedTubing.tubing2) tubingTotal += parseFloat(selectedTubing.tubing2) ** 2;

                // if (tubingTotal >= casingID ** 2) return alert('Total tubing area must be smaller than casing ID!');

                if (selectedTubing.tubing1) tubinTotalSize += parseFloat(selectedTubing.tubing1);
                if (selectedTubing.tubing2) tubinTotalSize += parseFloat(selectedTubing.tubing2);

                if (tubinTotalSize >= casingID) {
                    showModalAlert('Total tubing area must be smaller than casing ID!');
                    return

                }

                volume = ((casingID ** 2 - tubingTotal) / 1029.46) * totalLength;
                capacity = volume / totalLength;
                console.log('volume', volume);
                console.log('capacity', capacity);

                break;
            default:
                // alert('Invalid calculation type!');
                showModalAlert('Invalid calculation type!');
                return;
        }

        setResults({
            volumeBbl: volume,
            volumeGal: volume * 42,
            volumeFt3: volume * 5.61,
            volumeLtr: volume * 158.99,
            volumeM3: volume * 0.16,
            annCapBblPerFt: capacity,
            annCapGalPerFt: capacity * 42,
            annCapFtPerGal: 1 / (capacity * 42),
            annCapFtPerBbl: 1 / capacity,
            annCapFt3PerFt: capacity * 5.61,
            annCapFtPerFt3: 1 / (capacity * 5.61),
        });
    };

    // convert casing ODs fetched from db from inches to mm
    // const odMetric = Array.isArray(od) && od.length > 0 ? od.map(size => (size * 25.4).toFixed(1)): [];

    return (
        <Container className="d-flex flex-column min-vh-100">
            <h2 className='mt-5 mb-3 text-start'>Capacity & Volume Calculator</h2>
            <Form>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label className='fw-bold'>Calculation Type</Form.Label>
                            <Form.Control as="select" value={calculationType} onChange={handleCalculationTypeChange}>
                                <option value="openHole">Open Hole Capacity</option>
                                <option value="casing">Casing Capacity</option>
                                <option value="annularOpenHoleCasing">Annular Capacity (Open Hole & Casing)</option>
                                <option value="annularCasingTubing">Annular Capacity (Casing & Tubing Strings)</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label className='fw-bold'>Units</Form.Label>
                            <Form.Control as="select" value={unit} onChange={(e) => setUnit(e.target.value)}>
                                <option value="Field">Field Units</option>
                                <option value="Metric">Metric</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    {(calculationType === 'openHole' || calculationType === 'annularOpenHoleCasing') && (
                        <Col xs={12} md={6}>
                            <Form.Group>
                                <Form.Label className='fw-bold'>Hole Diam ({diamUnit})</Form.Label>
                                <Form.Control type="number" value={holeDiameter} onChange={(e) => setHoleDiameter(e.target.value)} />
                            </Form.Group>
                        </Col>
                    )}

                    {calculationType !== 'openHole' && (
                        <Col xs={12} md={6}>
                            <Form.Group controlId="od">
                                <Form.Label className='fw-bold'>Casing OD ({diamUnit})</Form.Label>
                                <Form.Control as="select" value={formData.casing_size} onChange={handleOdChange} required>
                                    <option value="">Select OD</option>
                                    {od.map((value, index) => (
                                        <option key={index} value={value}>
                                            {unit === 'Metric' ? convertToMetric(value, 'diameter') : value}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    )}

                    {(calculationType === 'casing' || calculationType === 'annularCasingTubing') && (
                        <>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="nomWt">
                                    <Form.Label className='fw-bold'>Nominal Weight ({weightUnit}) </Form.Label>
                                    <Form.Control as="select" value={formData.casing_wt} onChange={handleNomWtChange} required>
                                        <option value="">Select Nom. Wt.</option>
                                        {nomWeights.map((nomWt, index) => (
                                            <option key={index} value={nomWt.casing_wt}>
                                                {unit === 'Metric' ? convertToMetric(nomWt.casing_wt, 'weight') : nomWt.casing_wt}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="dpId">
                                    <Form.Label className='fw-bold'>Internal Diameter ({diamUnit})</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="casing_id"
                                        value={casingID ? (unit === 'Metric' ? convertToMetric(casingID, 'diameter') : casingID) || '' : ''}
                                        // value={unit === 'Metric' ? convertToMetric(casingID, 'diameter') : value}
                                        readOnly
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </>
                    )}

                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label className='fw-bold'>Length ({lengthUnit})</Form.Label>
                            <Form.Control type="number" value={length} onChange={(e) => setLength(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>

                {calculationType === 'annularCasingTubing' && (
                    <Row className="mt-3">
                        <Col xs={12} md={6}>
                            <Form.Group>
                                <Form.Label className='fw-bold'>Select Tubing String 1 OD ({diamUnit}) </Form.Label>
                                <Form.Control as="select" onChange={(e) => setSelectedTubing({ ...selectedTubing, tubing1: e.target.value })}>
                                    <option value="">Select Tubing</option>
                                    {tubingOptions.map((od, index) =>
                                    <option key={index} value={od}>
                                        {unit === 'Metric' ? convertToMetric(od, 'diameter') : od}
                                    </option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group>
                                <Form.Label className='fw-bold'>Select Tubing String 2 OD ({diamUnit}) (Optional)</Form.Label>
                                <Form.Control as="select" onChange={(e) => setSelectedTubing({ ...selectedTubing, tubing2: e.target.value })}>
                                    <option value="">Select Tubing</option>
                                    {tubingOptions.map((od, index) =>
                                        <option key={index} value={od}>
                                            {unit === 'Metric' ? convertToMetric(od, 'diameter') : od}

                                        </option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                )}

                <Row>
                    <Col xs={12} className='mt-3'>
                        <Button variant="secondary" onClick={() => window.location.reload()}>
                            Reset
                        </Button>{' '}
                        <Button variant="primary" onClick={calculateCapacities}>Calculate</Button>
                    </Col>
                </Row>
            </Form>

            {/* Modal for error messages */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>

            {results && (
                <Table striped bordered hover className="mt-4">
                    <thead>
                        <tr>
                            <th>Unit</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>Volume (bbl)</td><td>{results.volumeBbl ? results.volumeBbl.toFixed(2) : ''}</td></tr>
                        <tr><td>Volume (gal)</td><td>{results.volumeGal ? results.volumeGal.toFixed(2) : ''}</td></tr>
                        <tr><td>Volume (ft³)</td><td>{results.volumeFt3 ? results.volumeFt3.toFixed(2) : ''}</td></tr>
                        <tr><td>Volume (Ltr)</td><td>{results.volumeLtr ? results.volumeLtr.toFixed(2) : ''}</td></tr>
                        <tr><td>Volume (m3)</td><td>{results.volumeM3 ? results.volumeM3.toFixed(2) : ''}</td></tr>
                        <tr><td>Capacity (bbl/ft)</td><td>{results.annCapBblPerFt ? results.annCapBblPerFt.toFixed(4) : ''}</td></tr>
                        <tr><td>Capacity (gal/ft)</td><td>{results.annCapGalPerFt ? results.annCapGalPerFt.toFixed(4) : ''}</td></tr>
                        <tr><td>Capacity (ft/gal)</td><td>{results.annCapFtPerGal ? results.annCapFtPerGal.toFixed(4) : ''}</td></tr>
                        <tr><td>Capacity (ft/bbl)</td><td>{results.annCapFtPerBbl ? results.annCapFtPerBbl.toFixed(4) : ''}</td></tr>
                        <tr><td>Capacity (ft³/ft)</td><td>{results.annCapFt3PerFt ? results.annCapFt3PerFt.toFixed(4) : ''}</td></tr>
                        <tr><td>Capacity (ft/ft³)</td><td>{results.annCapFtPerFt3 ? results.annCapFtPerFt3.toFixed(4) : ''}</td></tr>
                    </tbody>
                </Table>
            )}
        </Container>

    );
}

export default CapacityCalculator;
