import React, { useState } from "react";
import { Form, Button, Table, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
// import axios from "axios"; // this is reserved for use with fetchWITSMLData below

const DepthParameterInput = ({ onDataUpdate }) => {
    const [isManualEntry, setIsManualEntry] = useState(true);
    const [data, setData] = useState([]);
    const [newRow, setNewRow] = useState({
        depth: "",
        slackOffWeight: "",
        pickUpWeight: "",
        weightRotatingOffBottom: "",
        rpm: "",
        offBottomTorque: "",
        onBottomTorque: "",
        breakOffTorque: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewRow({ ...newRow, [name]: value });
    };

    const addRow = () => {
        const updatedData = [...data, newRow];
        setData(updatedData);
        setNewRow({
            depth: "",
            slackOffWeight: "",
            pickUpWeight: "",
            weightRotatingOffBottom: "",
            rpm: "",
            offBottomTorque: "",
            onBottomTorque: "",
            breakOffTorque: "",
        });
        onDataUpdate(updatedData); // Send data to parent component
    };

    const fetchWITSData = async () => {
        // Mock WITS data fetching
        const witsData = [
            { depth: 1000, slackOffWeight: 10, pickUpWeight: 15, weightRotatingOffBottom: 12, rpm: 50, offBottomTorque: 10000, onBottomTorque: 12000, breakOffTorque: 9000 },
            // Add more data points
        ];
        setData(witsData);
        onDataUpdate(witsData); // Send WITS data to parent component
    };

    // The code below is reserved for use when connecting to a live WITSML connection
    /*
    const fetchWITSMLData = async () => {
        try {
            const response = await axios.post("https://your-wellsite-provider.com/witsml", {
                headers: {
                    "Content-Type": "application/xml",
                    Authorization: "Bearer YOUR_API_TOKEN", // Replace with actual credentials
                },
                data: `
        <witsmlRequest>
          <!-- Your WITSML request here -->
        </witsmlRequest>
      `,
            });

            // Parse and process the response
            console.log("WITSML Data:", response.data);
            return response.data;
        } catch (error) {
            console.error("Error fetching WITSML data:", error);
            return null;
        }
    };
    */

    return (
        <div className="container">
            {/* <h2>Depth vs. Parameters Input</h2> */}

            {/* Toggle between Manual Entry and WITS */}
            <ToggleButtonGroup type="radio" name="dataEntryMode" value={isManualEntry} onChange={(val) => setIsManualEntry(val)}>
                <ToggleButton id="manual" value={true}>
                    Manual Entry
                </ToggleButton>
                <ToggleButton id="wits" value={false}>
                    WITSML
                </ToggleButton>
            </ToggleButtonGroup>

            {isManualEntry ? (
                <Form className="mt-3">
                    <Form.Group>
                        <Form.Label>Depth</Form.Label>
                        <Form.Control type="number" name="depth" value={newRow.depth} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Slack-Off Weight</Form.Label>
                        <Form.Control type="number" name="slackOffWeight" value={newRow.slackOffWeight} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Pick-Up Weight</Form.Label>
                        <Form.Control type="number" name="pickUpWeight" value={newRow.pickUpWeight} onChange={handleInputChange} />
                    </Form.Group>
                    {/* Add other input fields here */}
                    <Form.Group>
                        <Form.Label>Weight Rotating Off Bottom</Form.Label>
                        <Form.Control type="number" name="weightRotatingOffBottom" value={newRow.weightRotatingOffBottom} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>RPM</Form.Label>
                        <Form.Control type="number" name="rpm" value={newRow.rpm} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Off-Bottom Torque</Form.Label>
                        <Form.Control type="number" name="offBottomTorque" value={newRow.offBottomTorque} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>On-Bottom Torque</Form.Label>
                        <Form.Control type="number" name="onBottomTorque" value={newRow.onBottomTorque} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Break-Off Torque</Form.Label>
                        <Form.Control type="number" name="breakOffTorque" value={newRow.breakOffTorque} onChange={handleInputChange} />
                    </Form.Group>
                    <Button variant="primary" onClick={addRow} className="mt-3 mb-3">
                        Add Row
                    </Button>
                </Form>
            ) : (
                <Button variant="secondary" onClick={fetchWITSData}>
                    Fetch WITS Data
                </Button>
            )}

            {/* Data Table */}
            <h6 className="mt-5 mb-3">Actual Torque and Drag Data</h6>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Depth</th>
                        <th>Slack-Off Weight</th>
                        <th>Pick-Up Weight</th>
                        <th>Weight Rotating Off Bottom</th>
                        <th>RPM</th>
                        <th>Off-Bottom Torque</th>
                        <th>On-Bottom Torque</th>
                        <th>Break-Off Torque</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td>{row.depth}</td>
                            <td>{row.slackOffWeight}</td>
                            <td>{row.pickUpWeight}</td>
                            <td>{row.weightRotatingOffBottom}</td>
                            <td>{row.rpm}</td>
                            <td>{row.offBottomTorque}</td>
                            <td>{row.onBottomTorque}</td>
                            <td>{row.breakOffTorque}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default DepthParameterInput;
