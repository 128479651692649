import React, { useState } from 'react';
import { Form, InputGroup, FormControl, Button, Dropdown, Row, Col } from 'react-bootstrap';

const UnitConverter = () => {
    const [value, setValue] = useState('');
    const [conversionType, setConversionType] = useState('length');
    const [fromUnit, setFromUnit] = useState('feet');
    const [toUnit, setToUnit] = useState('meters');
    const [convertedValue, setConvertedValue] = useState(null);

    const conversionOptions = {
        length: ['meters', 'kilometers', 'inches', 'feet', 'miles', 'nautical miles'],
        pressure: ['psi', 'kPa', 'bar', 'kg/cm²', 'm H₂O (15°C)', 'ft H₂O (39°F)'],
        volume: ['cubic meters', 'cubic decimeters', 'cubic centimeters', 'cubic feet', 'gallons (imp)', 'gallons (US liquid)', 'barrels'],
        temperature: ['Celsius', 'Fahrenheit', 'Kelvin', 'Rankine', 'Reaumur'],
        density: ['psi/ft', 'bar/10m', 'kPa/m', 'lb/gal (US)', 'lb/ft³', 'kg/dm³', 'SG', 'API gravity'],
        rheology: ['cp', 'Pa·s', 'lbf/100 ft²', 'Pa'],
        force: ['N', 'dynes', 'kgf', 'poundals', 'lbf', 'decanewton'],
        mass: ['kilograms', 'pounds', 'tons (metric)', 'tons (long)', 'tons (short)'],
        area: ['square meters', 'square cm', 'hectares', 'square feet', 'acres', 'square inches'],
        power: ['watts', 'kilogram meters/sec', 'foot pounds/sec', 'horsepower (metric)', 'horsepower (british)', 'BTU/sec'],
        energy: ['joules', 'kilowatt hours', 'kilogram calories', 'kilogram meters', 'foot pounds', 'horsepower hours (metric)', 'horsepower hours (british)', 'BTU'],
        flowRate: ['gallons/min', 'liters/sec', 'liters/min', 'cubic meters/hour', 'barrels/day', 'bbl/min'],
        velocity: ['feet/sec', 'feet/min', 'feet/hour', 'meters/sec', 'meters/min', 'miles/hour', 'knots']

    };

    const roundToSignificantDigits = (num, digits) => {
        if (num === 0) return 0;
        const magnitude = Math.floor(Math.log10(Math.abs(num)));
        const factor = Math.pow(10, digits - magnitude - 1);
        return Math.round(num * factor) / factor;
    };

    const convertValue = () => {
        let converted;
        const val = parseFloat(value);
        if (isNaN(val)) {
            alert('Please enter a valid number');
            return;
        }

        if (!conversionOptions[conversionType].includes(fromUnit) || !conversionOptions[conversionType].includes(toUnit)) {
            alert('Invalid unit conversion. The selected units do not belong to the same category.');
            return;
        }

        if (fromUnit === toUnit) {
            setConvertedValue(val);
            return;
        }

        switch (conversionType) {
            case 'length':
                converted = convertLength(val, fromUnit, toUnit);
                break;
            case 'pressure':
                converted = convertPressure(val, fromUnit, toUnit);
                break;
            case 'volume':
                converted = convertVolume(val, fromUnit, toUnit);
                break;
            case 'temperature':
                converted = convertTemperature(val, fromUnit, toUnit);
                break;
            case 'density':
                converted = convertDensity(val, fromUnit, toUnit);
                break;
            case 'rheology':
                converted = convertRheology(val, fromUnit, toUnit);
                break;
            case 'force':
                converted = convertForce(val, fromUnit, toUnit);
                break;
            case 'mass':
                converted = convertMass(val, fromUnit, toUnit);
                break;
            case 'area':
                converted = convertArea(val, fromUnit, toUnit);
                break;
            case 'power':
                converted = convertPower(val, fromUnit, toUnit);
                break;
            case 'energy':
                converted = convertEnergy(val, fromUnit, toUnit);
                break;
            case 'flowRate':
                converted = convertFlowRate(val, fromUnit, toUnit);
                break;
            case 'velocity':
                converted = convertVelocity(val, fromUnit, toUnit);
                break;
            default:
                converted = val;
        }

        setConvertedValue(roundToSignificantDigits(converted, 5));
    };

    const convertLength = (value, from, to) => {
        const lengthConversions = {
            'meters': { 'kilometers': value / 1000, 'inches': value * 39.37, 'feet': value * 3.281, 'miles': value * 0.0006214, 'nautical miles': value * 0.0005396 },
            'kilometers': { 'meters': value * 1000, 'inches': value * 39370, 'feet': value * 3281, 'miles': value * 0.6214, 'nautical miles': value * 0.5396 },
            'inches': { 'meters': value * 0.0254, 'kilometers': value * 2.54e-5, 'feet': value / 12, 'miles': value * 1.578e-5, 'nautical miles': value * 1.371e-5 },
            'feet': { 'meters': value * 0.3048, 'kilometers': value * 0.0003048, 'inches': value * 12, 'miles': value * 0.0001894, 'nautical miles': value * 0.0001645 },
            'miles': { 'meters': value * 1609, 'kilometers': value * 1.609, 'inches': value * 63360, 'feet': value * 5280, 'nautical miles': value * 0.8684 },
            'nautical miles': { 'meters': value * 1853, 'kilometers': value * 1.853, 'inches': value * 72960, 'feet': value * 6076, 'miles': value * 1.152 }
        };
        return lengthConversions[from] && lengthConversions[from][to] ? lengthConversions[from][to] : value;
    };

    const convertPressure = (value, from, to) => {
        const pressureConversions = {
            'psi': { 'kPa': value * 6.894757, 'bar': value * 0.0689476, 'kg/cm²': value * 0.07037, 'm H₂O (15°C)': value * 0.703703, 'ft H₂O (39°F)': value * 2.30665 },
            'bar': { 'kPa': value * 100, 'kg/cm²': value * 1.019716, 'psi': value * 14.5038, 'm H₂O (15°C)': value * 10.20635, 'ft H₂O (39°F)': value * 33.4552 },
            'kg/cm²': { 'kPa': value * 98.0665, 'bar': value * 0.980665, 'psi': value * 14.2233, 'm H₂O (15°C)': value * 10.0090, 'ft H₂O (39°F)': value * 32.80834 },
            'kPa': { 'bar': value * 0.01, 'kg/cm²': value * 0.01097, 'psi': value * 0.145038, 'm H₂O (15°C)': value * 0.1020635, 'ft H₂O (39°F)': value * 0.334552 },
            'm H₂O (15°C)': { 'ft H₂O (39°F)': value * 3.27788, 'kPa': value * 9.79782, 'bar': value * 0.0979782, 'kg/cm²': value * 0.09991, 'psi': value * 1.42106 },
            'ft H₂O (39°F)': { 'm H₂O (15°C)': value * 0.305075, 'kPa': value * 2.98907, 'bar': value * 0.0298907, 'kg/cm²': value * 0.030480, 'psi': value * 0.433529 }
        };
        return pressureConversions[from][to];
    };
    const convertVolume = (value, from, to) => {
        const volumeConversions = {
            'cubic meters': { 'cubic decimeters': value * 1000, 'cubic centimeters': value * 1e6, 'cubic feet': value * 35.31, 'gallons (imp)': value * 220, 'gallons (US liquid)': value * 264.2, 'barrels': value * 6.29 },
            'cubic decimeters': { 'cubic meters': value / 1000, 'cubic centimeters': value * 1000, 'cubic feet': value * 0.03531, 'gallons (imp)': value * 0.22, 'gallons (US liquid)': value * 0.2642, 'barrels': value * 0.00629 },
            'cubic centimeters': { 'cubic meters': value / 1e6, 'cubic decimeters': value / 1000, 'cubic feet': value * 3.531e-5, 'gallons (imp)': value * 2.2e-4, 'gallons (US liquid)': value * 2.642e-4, 'barrels': value * 6.29e-6 },
            'cubic feet': { 'cubic meters': value / 35.31, 'cubic decimeters': value / 0.03531, 'cubic centimeters': value / 3.531e-5, 'gallons (imp)': value * 6.229, 'gallons (US liquid)': value * 7.481, 'barrels': value * 0.1781 },
            'gallons (imp)': { 'cubic meters': value / 220, 'cubic decimeters': value / 0.22, 'cubic centimeters': value / 2.2e-4, 'cubic feet': value / 6.229, 'gallons (US liquid)': value * 1.201, 'barrels': value * 0.02859 },
            'gallons (US liquid)': { 'cubic meters': value / 264.2, 'cubic decimeters': value / 0.2642, 'cubic centimeters': value / 2.642e-4, 'cubic feet': value / 7.481, 'gallons (imp)': value / 1.201, 'barrels': value * 0.02381 },
            'barrels': { 'cubic meters': value / 6.29, 'cubic decimeters': value / 0.00629, 'cubic centimeters': value / 6.29e-6, 'cubic feet': value / 0.1781, 'gallons (imp)': value / 0.02859, 'gallons (US liquid)': value / 0.02381 }
        };
        return volumeConversions[from] && volumeConversions[from][to] ? volumeConversions[from][to] : value;
    };

    const convertTemperature = (value, from, to) => {
        if (from === 'Celsius' && to === 'Fahrenheit') return value * 9 / 5 + 32;
        if (from === 'Celsius' && to === 'Kelvin') return value + 273.15;
        if (from === 'Celsius' && to === 'Rankine') return value * 1.8 + 492;
        if (from === 'Celsius' && to === 'Reaumur') return value * 0.8;

        if (from === 'Fahrenheit' && to === 'Celsius') return (value - 32) * 5 / 9;
        if (from === 'Fahrenheit' && to === 'Kelvin') return (value - 32) * 5 / 9 + 273.15;
        if (from === 'Fahrenheit' && to === 'Rankine') return (value) + 460;
        if (from === 'Fahrenheit' && to === 'Reaumur') return (value - 32) * 0.444;

        if (from === 'Kelvin' && to === 'Celsius') return value - 273.15;
        if (from === 'Kelvin' && to === 'Fahrenheit') return (value - 273.15) * 9 / 5 + 32;
        if (from === 'Kelvin' && to === 'Rankine') return (value - 273.15) * 9 / 5 + 492;
        if (from === 'Kelvin' && to === 'Reaumur') return (value) * 0.8;

        if (from === 'Rankine' && to === 'Celsius') return (value - 492) / 1.8;
        if (from === 'Rankine' && to === 'Reaumur') return (value - 492) * 0.444;
        if (from === 'Rankine' && to === 'Fahrenheit') return (value - 460);
        if (from === 'Rankine' && to === 'Kelvin') return (value - 492) / 1.8 + 273;

        if (from === 'Reaumur' && to === 'Celsius') return (value) * 1.25;
        if (from === 'Reaumur' && to === 'Fahrenheit') return (value) * 2.25 + 35;
        if (from === 'Reaumur' && to === 'Kelvin') return (value) * 1.25 + 273;
        if (from === 'Reaumur' && to === 'Rankine') return (value) * 2.25 + 492;


        return value;
    };

    const convertDensity = (value, from, to) => {
        const apiToSG = 141.5 / (value + 131.5);
        const sgToApi = (141.5 / value) - 131.5;
        const sgToPsiFt = value * 0.4335;
        const sgToBar10m = value * 2.26206;
        const sgToKPaM = value * 9.807;
        const sgToLbGal = value * 8.326;
        const sgToLbFt3 = value * 62.4;
        const sgToKgDm3 = value * 1.0;

        const densityConversions = {
            'psi/ft': { 'kPa/m': value * 22.62059, 'bar/10m': value * 2.26206, 'lb/gal (US)': value * 19.25, 'lb/ft³': value * 144.0, 'kg/dm³': value * 2.307 },
            'bar/10m': { 'kPa/m': value * 10.0, 'psi/ft': value * 0.442075, 'lb/gal (US)': value * 8.51, 'lb/ft³': value * 63.66, 'kg/dm³': value * 1.020 },
            'kPa/m': { 'bar/10m': value * 0.10, 'psi/ft': value * 0.0442075, 'lb/gal (US)': value * 0.851, 'lb/ft³': value * 6.366, 'kg/dm³': value * 0.1020 },
            'lb/gal (US)': { 'kPa/m': value * 1.175, 'bar/10m': value * 0.1175, 'psi/ft': value * 0.05197, 'lb/ft³': value * 7.480519, 'kg/dm³': value * 0.1198264 },
            'lb/ft³': { 'kPa/m': value * 0.15717, 'bar/10m': value * 0.01571, 'psi/ft': value * 0.00694, 'lb/gal (US)': value * 0.1336897, 'kg/dm³': value * 0.01601846 },
            'kg/dm³': { 'kPa/m': value * 9.807, 'bar/10m': value * 0.9807, 'psi/ft': value * 0.4335, 'lb/gal (US)': value * 8.34540, 'lb/ft³': value * 62.4280 },
            'SG': { 'API gravity': sgToApi, 'psi/ft': sgToPsiFt, 'bar/10m': sgToBar10m, 'kPa/m': sgToKPaM, 'lb/gal (US)': sgToLbGal, 'lb/ft³': sgToLbFt3, 'kg/dm³': sgToKgDm3 },
            'API gravity': { 'SG': apiToSG, 'psi/ft': apiToSG * 0.4335, 'bar/10m': apiToSG * 2.26206, 'kPa/m': apiToSG * 9.807, 'lb/gal (US)': apiToSG * 8.326, 'lb/ft³': apiToSG * 62.4, 'kg/dm³': apiToSG * 1.0 }
        };
        return densityConversions[from] && densityConversions[from][to] ? densityConversions[from][to] : value;
    };

    const convertRheology = (value, from, to) => {
        const rheologyConversions = {
            'cp': { 'Pa·s': value * 1.0e-3 },
            'Pa·s': { 'cp': value * 1.0e3 },
            'lbf/100 ft²': { 'Pa': value * 0.4788 },
            'Pa': { 'lbf/100 ft²': value / 0.4788 }
        };
        return rheologyConversions[from] && rheologyConversions[from][to] ? rheologyConversions[from][to] : value;
    };

    const convertForce = (value, from, to) => {
        const forceConversions = {
            'N': { 'dynes': value * 1e5, 'kgf': value / 9.807, 'poundals': value * 7.233, 'lbf': value / 4.448, 'decanewton': value / 10 },
            'dynes': { 'N': value / 1e5, 'kgf': value / 9.807e5, 'poundals': value / 138.4, 'lbf': value / 4.448e5, 'decanewton': value / 1e6 },
            'kgf': { 'N': value * 9.807, 'dynes': value * 9.807e5, 'poundals': value * 70.93, 'lbf': value * 2.205, 'decanewton': value * 0.981 },
            'poundals': { 'N': value / 7.233, 'dynes': value * 138.4, 'kgf': value / 70.93, 'lbf': value / 32.17, 'decanewton': value / 72.33 },
            'lbf': { 'N': value * 4.448, 'dynes': value * 4.448e5, 'kgf': value / 2.205, 'poundals': value * 32.17, 'decanewton': value * 0.445 },
            'decanewton': { 'N': value * 10, 'dynes': value * 1e6, 'kgf': value * 1.02, 'poundals': value * 72.33, 'lbf': value * 2.248 }
        };
        return forceConversions[from] && forceConversions[from][to] ? forceConversions[from][to] : value;
    };

    const convertArea = (value, from, to) => {
        const areaConversions = {
            'square meters': { 'square cm': value * 10000, 'hectares': value / 10000, 'square feet': value * 10.76, 'acres': value / 4047, 'square inches': value * 1550 },
            'square cm': { 'square meters': value / 10000, 'hectares': value / 100000000, 'square feet': value / 929, 'acres': value / 40470000 },
            'hectares': { 'square meters': value * 10000, 'square cm': value * 100000000, 'square feet': value * 107639, 'acres': value * 2.471 },
            'square feet': { 'square meters': value / 10.76, 'square cm': value * 929, 'hectares': value / 107639, 'acres': value / 43560 },
            'acres': { 'square meters': value * 4047, 'square cm': value * 40470000, 'hectares': value / 2.471, 'square feet': value * 43560 }
        };
        return areaConversions[from] && areaConversions[from][to] ? areaConversions[from][to] : value;
    };

    const convertMass = (value, from, to) => {
        const massConversions = {
            'kilograms': { 'pounds': value * 2.205, 'tons (metric)': value / 1000, 'tons (long)': value / 1016, 'tons (short)': value / 907.2 },
            'pounds': { 'kilograms': value / 2.205, 'tons (metric)': value / 2205, 'tons (long)': value / 2240, 'tons (short)': value / 2000 },
            'tons (metric)': { 'kilograms': value * 1000, 'pounds': value * 2205, 'tons (long)': value * 0.9842, 'tons (short)': value * 1.102 },
            'tons (long)': { 'kilograms': value * 1016, 'pounds': value * 2240, 'tons (metric)': value / 0.9842, 'tons (short)': value * 1.12 },
            'tons (short)': { 'kilograms': value * 907.2, 'pounds': value * 2000, 'tons (metric)': value / 1.102, 'tons (long)': value / 1.12 }
        };
        return massConversions[from] && massConversions[from][to] ? massConversions[from][to] : value;
    };

    const convertPower = (value, from, to) => {
        const powerConversions = {
            'watts': { 'kilogram meters/sec': value * 1.02, 'foot pounds/sec': value * 0.7376, 'horsepower (metric)': value / 735.5, 'horsepower (british)': value / 745.7, 'BTU/sec': value / 1055 },
            'kilogram meters/sec': { 'watts': value / 1.02, 'foot pounds/sec': value * 0.7233, 'horsepower (metric)': value / 75, 'horsepower (british)': value / 76.04, 'BTU/sec': value / 107.6 },
            'foot pounds/sec': { 'watts': value / 0.7376, 'kilogram meters/sec': value / 0.7233, 'horsepower (metric)': value / 542.5, 'horsepower (british)': value / 550, 'BTU/sec': value / 778 },
            'horsepower (metric)': { 'watts': value * 735.5, 'kilogram meters/sec': value * 75, 'foot pounds/sec': value * 542.5, 'horsepower (british)': value * 0.9863, 'BTU/sec': value * 697.2 },
            'horsepower (british)': { 'watts': value * 745.7, 'kilogram meters/sec': value * 76.04, 'foot pounds/sec': value * 550, 'horsepower (metric)': value * 1.014, 'BTU/sec': value * 707 },
            'BTU/sec': { 'watts': value * 1055, 'kilogram meters/sec': value * 107.6, 'foot pounds/sec': value * 778, 'horsepower (metric)': value / 697.2, 'horsepower (british)': value / 707 }
        };
        return powerConversions[from] && powerConversions[from][to] ? powerConversions[from][to] : value;
    };

    const convertEnergy = (value, from, to) => {
        const energyConversions = {
            'joules': { 'kilowatt hours': value / 3.6e6, 'kilogram calories': value / 4187, 'kilogram meters': value / 9.807, 'foot pounds': value / 1.356, 'horsepower hours (metric)': value / 2.648e6, 'horsepower hours (british)': value / 2.685e6, 'BTU': value / 1055 },
            'kilowatt hours': { 'joules': value * 3.6e6, 'kilogram calories': value * 860, 'kilogram meters': value * 367.1, 'foot pounds': value * 2655, 'horsepower hours (metric)': value * 1.36, 'horsepower hours (british)': value * 1.341, 'BTU': value * 3413 },
            'kilogram calories': { 'joules': value * 4187, 'kilowatt hours': value / 860, 'kilogram meters': value * 426.9, 'foot pounds': value * 3087, 'horsepower hours (metric)': value * 1.581, 'horsepower hours (british)': value * 1.559, 'BTU': value * 3968 },
            'kilogram meters': { 'joules': value * 9.807, 'kilowatt hours': value / 367.1, 'kilogram calories': value / 426.9, 'foot pounds': value * 7.233, 'horsepower hours (metric)': value * 3.704e-6, 'horsepower hours (british)': value * 3.653e-6, 'BTU': value * 9.297e-3 },
            'foot pounds': { 'joules': value * 1.356, 'kilowatt hours': value / 2655, 'kilogram calories': value / 3087, 'kilogram meters': value / 7.233, 'horsepower hours (metric)': value * 5.121e-7, 'horsepower hours (british)': value * 5.051e-7, 'BTU': value * 1.285e-3 },
            'horsepower hours (metric)': { 'joules': value * 2.648e6, 'kilowatt hours': value / 1.36, 'kilogram calories': value / 1.581, 'kilogram meters': value / 3.704e-6, 'foot pounds': value / 5.121e-7, 'horsepower hours (british)': value * 0.9863, 'BTU': value * 2.51e3 },
            'horsepower hours (british)': { 'joules': value * 2.685e6, 'kilowatt hours': value / 1.341, 'kilogram calories': value / 1.559, 'kilogram meters': value / 3.653e-6, 'foot pounds': value / 5.051e-7, 'horsepower hours (metric)': value * 1.014, 'BTU': value * 2.545e3 },
            'BTU': { 'joules': value * 1055, 'kilowatt hours': value / 3413, 'kilogram calories': value / 3968, 'kilogram meters': value / 9.297e-3, 'foot pounds': value / 1.285e-3, 'horsepower hours (metric)': value / 2.51e3, 'horsepower hours (british)': value / 2.545e3 }
        };
        return energyConversions[from] && energyConversions[from][to] ? energyConversions[from][to] : value;
    };

    const convertFlowRate = (value, from, to) => {
        const flowRateConversions = {
            'gallons/min': { 'liters/sec': value * 0.06309, 'liters/min': value * 3.78541, 'cubic meters/hour': value * 0.2271, 'barrels/day': value * 34.2857, 'bbl/min': value * 0.0238095 },
            'liters/sec': { 'gallons/min': value / 0.06309, 'liters/min': value * 60, 'cubic meters/hour': value * 3.6, 'barrels/day': value * 571.42, 'bbl/min': value * 0.37854 },
            'liters/min': { 'gallons/min': value / 3.78541, 'liters/sec': value / 60, 'cubic meters/hour': value / 16.6667, 'barrels/day': value * 9.4782, 'bbl/min': value / 158.987 },
            'cubic meters/hour': { 'gallons/min': value / 0.2271, 'liters/sec': value / 3.6, 'liters/min': value * 16.6667, 'barrels/day': value * 6.2898, 'bbl/min': value / 2.4 },
            'barrels/day': { 'gallons/min': value / 34.2857, 'liters/sec': value / 571.42, 'liters/min': value / 9.4782, 'cubic meters/hour': value / 6.2898, 'bbl/min': value / 1440 },
            'bbl/min': { 'gallons/min': value / 0.0238095, 'liters/sec': value / 0.37854, 'liters/min': value * 158.987, 'cubic meters/hour': value * 2.4, 'barrels/day': value * 1440 }
        };
        return flowRateConversions[from] && flowRateConversions[from][to] ? flowRateConversions[from][to] : value;
    };

    const convertVelocity = (value, from, to) => {
        const velocityConversions = {
            'feet/sec': { 'meters/sec': value * 0.3048, 'miles/hour': value * 0.681818, 'knots': value * 0.592484, 'feet/min': value * 60, 'feet/hour': value * 3600 },
            'meters/sec': { 'feet/sec': value / 0.3048, 'miles/hour': value * 2.23694, 'knots': value * 1.94384, 'meters/min': value * 60 },
            'miles/hour': { 'feet/sec': value / 0.681818, 'meters/sec': value / 2.23694, 'knots': value * 0.868976 },
            'knots': { 'feet/sec': value / 0.592484, 'meters/sec': value / 1.94384, 'miles/hour': value / 0.868976 },
            'feet/min': { 'feet/sec': value / 60, 'feet/hour': value * 60 },
            'feet/hour': { 'feet/sec': value / 3600, 'feet/min': value / 60 },
            'meters/min': { 'meters/sec': value / 60 }
        };
        return velocityConversions[from] && velocityConversions[from][to] ? velocityConversions[from][to] : value;
    };

    return (
        <Row>
            <Col md={6}>
            <h3>Unit Converter</h3>
            <Form>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Enter value"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                            type="number"
                            style={{ minWidth: '150px' }}

                    />
                    <Dropdown onSelect={(eventKey) => setConversionType(eventKey)}>
                        <Dropdown.Toggle variant="secondary">
                            {conversionType.charAt(0).toUpperCase() + conversionType.slice(1)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {Object.keys(conversionOptions).map((type) => (
                                <Dropdown.Item key={type} eventKey={type}>
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown onSelect={(eventKey) => setFromUnit(eventKey)}>
                        <Dropdown.Toggle variant="secondary">
                            {fromUnit}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {conversionOptions[conversionType].map((unit) => (
                                <Dropdown.Item key={unit} eventKey={unit}>
                                    {unit}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown onSelect={(eventKey) => setToUnit(eventKey)}>
                        <Dropdown.Toggle variant="secondary">
                            {toUnit}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {conversionOptions[conversionType].map((unit) => (
                                <Dropdown.Item key={unit} eventKey={unit}>
                                    {unit}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant="primary" onClick={convertValue}>
                        Convert
                    </Button>
                </InputGroup>
            </Form>
            {convertedValue !== null && (
                <p>
                    {value} {fromUnit} is equal to {convertedValue} {toUnit}
                </p>
                )}
            </Col>
        </Row>
    );
};

export default UnitConverter;
