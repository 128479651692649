import React from "react";
import { Scatter } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, LinearScale, CategoryScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, LinearScale, CategoryScale);

const TorqueAndDragChart = ({ legendPosition, results, parameters, frictionFactors, selectedFactors, chartDataTnDActual }) => {
    console.log('results: ', results);
    // Prepare data for the chart
    const depthData = results.map((row) => row.d_depth);
    const inclinationData = results.map((row) => row.inclinationBottom);
    const puwData = results.map((row) => row.puw);
    const sowData = results.map((row) => row.sow);
    const rotData = results.map((row) => row.rot);
    const slideData = results.map((row) => row.sliding);
    const reamData = results.map((row) => row.reaming);
    const depthActual = Array.isArray(chartDataTnDActual) ? chartDataTnDActual?.map((row) => row.depth) : [];
    const sowActualData = Array.isArray(chartDataTnDActual) ? chartDataTnDActual?.map((row) => row.slackOffWeight) : [];
    const puwActualData = Array.isArray(chartDataTnDActual) ? chartDataTnDActual?.map((row) => row.pickUpWeight) : [];
    const rotOffBtmActualData = Array.isArray(chartDataTnDActual) ? chartDataTnDActual?.map((row) => row.weightRotatingOffBottom) : [];

    // fricttion factors
    const muTrippingoutCh = frictionFactors.casedHole.trippingOut;
    const muTrippingInCh = frictionFactors.casedHole.trippingIn;
    const muRotatingOffBottomCh = frictionFactors.casedHole.rotatingOffBottom;
    // const muRotatingOnBottomCh = frictionFactors.casedHole.rotatingOnBottom;
    const muSlideDrillingCh = frictionFactors.casedHole.slideDrilling;
    const muBackReamingCh = frictionFactors.casedHole.backReaming;

    const muTrippingoutOh = frictionFactors.openHole.trippingOut;
    const muTrippingInOh = frictionFactors.openHole.trippingIn;
    const muRotatingOffBottomOh = frictionFactors.openHole.rotatingOffBottom;
    // const muRotatingOnBottomOh = frictionFactors.openHole.rotatingOnBottom;
    const muSlideDrillingOh = frictionFactors.openHole.slideDrilling;
    const muBackReamingOh = frictionFactors.openHole.backReaming;

    // const trippingOutSelected = selectedFactors.trippingOut;
    // const trippingInSelected = selectedFactors.trippingIn;
    // const rotatingOffBottomSelected = selectedFactors.rotatingOffBottom;
    // const rotatingOnBottomSelected = selectedFactors.rotatingOnBottom;
    const slideDrillingSelected = selectedFactors.slideDrilling;
    const backReamingSelected = selectedFactors.backReaming;

    const chartData = {
        datasets: [
            {
                label: "Inclination",
                data: depthData.map((depth, i) => ({
                    x: inclinationData[i],
                    y: depth,
                })),
                borderColor: "blue",
                backgroundColor: "blue",
                showLine: true, // Adds the connecting line
                tension: 0.3,
                pointStyle: false,
            },
            {
                label: `PUW, CH: ${muTrippingoutCh}, OH: ${muTrippingoutOh}`,
                data: depthData.map((depth, i) => ({
                    x: puwData[i] / 1000, // Kips
                    y: depth,
                })),
                borderColor: "green",
                backgroundColor: "green",
                showLine: true,
                tension: 0.3,
                pointStyle: false,
            },
            {
                label: `SOW, CH: ${muTrippingInCh}, OH: ${muTrippingInOh}`,
                data: depthData.map((depth, i) => ({
                    x: sowData[i] / 1000, // Kips
                    y: depth,
                })),
                borderColor: "red",
                backgroundColor: "red",
                showLine: true,
                tension: 0.3,
                pointStyle: false,
            },
            {
                label: `ROT, CH: ${muRotatingOffBottomCh}, OH: ${muRotatingOffBottomOh}`,
                data: depthData.map((depth, i) => ({
                    x: rotData[i] / 1000, // Kips
                    y: depth,
                })),
                borderColor: "indigo",
                backgroundColor: "indigo",
                showLine: true,
                tension: 0.3,
                pointStyle: false,
            },
            ...(slideDrillingSelected
                ? [
                    {
                        label: `Sliding, CH: ${muSlideDrillingCh}, OH: ${muSlideDrillingOh}`,
                        data: depthData.map((depth, i) => ({
                            x: slideData[i] / 1000, // Kips
                            y: depth,
                        })),
                        borderColor: "violet",
                        backgroundColor: "violet",
                        showLine: true,
                        tension: 0.3,
                        pointStyle: false,
                    },
                ]
                : []),
            ...(backReamingSelected
                ? [
                    {
                        label: `Reaming, CH: ${muBackReamingCh}, OH: ${muBackReamingOh}`,
                        data: depthData.map((depth, i) => ({
                            x: reamData[i] / 1000, // Kips
                            y: depth,
                        })),
                        borderColor: "orange",
                        backgroundColor: "orange",
                        showLine: true,
                        tension: 0.3,
                        pointStyle: false,
                    },
                ]
                : []),
            {
                label: "Actual SOW",
                data: depthActual?.map((depth, i) => ({
                    x: sowActualData[i],
                    y: depth,
                })),
                borderColor: "red",
                backgroundColor: "red",
                showLine: false, // Adds the connecting line
                pointStyle: 'cross',
            },
            {
                label: "Actual PUW",
                data: depthActual?.map((depth, i) => ({
                    x: puwActualData[i],
                    y: depth,
                })),
                borderColor: "green",
                backgroundColor: "green",
                showLine: false, // Adds the connecting line
                pointStyle: 'triangle',
            },
            {
                label: "Actual Rot Off-Btm Weight",
                data: depthActual?.map((depth, i) => ({
                    x: rotOffBtmActualData[i],
                    y: depth,
                })),
                borderColor: "indigo",
                backgroundColor: "indio",
                showLine: false, // Adds the connecting line
                pointStyle: 'rectRot',
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: legendPosition,
                labels: {
                    boxWidth: 10, // Adjust the width of the legend box
                    boxHeight: 3, // Adjust the height of the legend box
                    padding: 10, // Optional: Adjust spacing around legend items
                    // usePointStyle: true, // Use the point style in the legend

                },

            },
            title: {
                display: true,
                text: "Inclination, PUW, SOW vs. Depth",
            },
            datalabels: false,
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Inclination, PUW, SOW",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Depth",
                },
                reverse: true, // Reverse depth axis
            },
        },
    };

    return (
        <div style={{ height: "500px" }}>
            <Scatter data={chartData} options={chartOptions} />
        </div>
    );
};

export default TorqueAndDragChart;
