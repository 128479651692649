const processOdValues = (odValues) => {
    return odValues.map(od => {
        od = od.toString();
        if (!od.includes('.')) {
            return parseFloat(od).toFixed(1);
        }
        return od;
    });
};

const processOdValue = (odValue) => {
    const od = odValue.toString();
    if (!od.includes('.')) {
        return parseFloat(od).toFixed(1);
    }
    return od; // Ensure it returns a value even if no modification is needed
};

export { processOdValues, processOdValue };
