import React, { useContext } from "react"
import { useState } from 'react';
import { Tabs, Tab, Button, Modal } from 'react-bootstrap';
import HeaderSite from "./headerSite";
import DesignHeaders from "./designHeaders";
import { GlobalStateContext } from "../GlobalStateContext";
import { useSurveyData } from "../trajectory/SurveyDataProvider";
import { useNavigate } from "react-router-dom";
// import Steps from "../../pages/tabs_components/Steps";

// Use React.lazy to load the component only when it's required
const HeaderSurvey = React.lazy(() => import('../trajectory/headerSurvey'));
const DesignHoleSections = React.lazy(() => import('./designHoleSections'));


function DesignCaseTabs() {

    const { surveyData } = useSurveyData();
    const { selectedItems } = useContext(GlobalStateContext);

    // const [key, setKey] = useState('home');
    const [dataFromWellboreChild, setDataFromWellboreChild] = useState(null);

    // Callback function to receive data from child
    const receiveDataFromWellboreChild = (data) => {
        setDataFromWellboreChild(data);
    };

    const [activeKey, setActiveKey] = useState("home");

    const handleNext = () => {
        const tabOrder = ["home", "header", "site", "survey", "holes"];
        const currentIndex = tabOrder.indexOf(activeKey);
        const nextIndex = currentIndex + 1;

        if (nextIndex < tabOrder.length) {
            setActiveKey(tabOrder[nextIndex]);
        }
    };
    const [showModal, setShowModal] = useState(false); // State to control the modal

    const navigate = useNavigate();

    const handlePrevious = () => {
        const tabOrder = ["home", "header", "site", "survey", "holes"];
        const currentIndex = tabOrder.indexOf(activeKey);
        const previousIndex = currentIndex - 1;

        if (previousIndex > -1) {
            setActiveKey(tabOrder[previousIndex]);
        }
    };

    const navigateToWellInformation = () => {
        if (selectedItems?.hole_default_parameter_id) {
            navigate('/well_information')

        } else {
            setShowModal(true); // Show modal instead of alert
            
        }
    };

    // const site = selectedItems.well_design_case_name ? "site" : "";

    return (
        <>
            <Tabs
                id="controlled-tab-header"
                // defaultActiveKey="header"
                activeKey={activeKey}
                onSelect={(k) => setActiveKey(k)}
                className="mb-3 mt-5"
            >
                <Tab eventKey="home" title="Get Started"
                    
                >
                    <div className="">
                        <div className="text-end">
                            {/* <Steps /> */}
                            <Button variant="primary" onClick={handleNext}>
                                Start
                            </Button>
                        </div>
                        <div>
                            <h5>Design Case</h5>
                            <p><b>Step 1</b></p>
                            <p>Get started by creating, retrieving, and/or updating a design case in the adjacent tabs:</p>
                            <ul>
                                <li>
                                    Header information (company, block, field, well and wellbore)
                                </li>
                                <li>
                                    Site information (name of the installation, location type, depth references, and temperature gradient)
                                </li>
                                <li>
                                    Wellbore survey, and
                                </li>
                                <li>
                                    Casing scheme
                                </li>

                            </ul>
                            {/* <p>You can also delete an existing design case.</p> */}
                            <hr />
                            <p><b>Step 2</b></p>
                            <p>Select Well Information in the sidebar menu, and create the input data to perform various designs.
                                This includes, fluids rheology, BHA, rig equipment, drilling parameters and drill bit information.
                            </p>
                            <hr />
                            <p><b>Step 3</b></p>
                            <p>Select any of the design types under Modules and click calculate, to perform the design and output the results.</p>
                            <hr />
                            <p><b>Step 4</b></p>
                            <p>View Reports and Charts</p>
                        </div>

                    </div>
                </Tab>
                <Tab eventKey="header" title="Header">
                    {/* <h5>Header Information</h5> */}
                    <p></p>
                    <div className="text-end">
                        <Button variant="outline-primary" onClick={handlePrevious}>
                            Previous
                        </Button> {' '}
                        <Button variant="primary" onClick={handleNext} disabled={!selectedItems.well_design_case_name}>
                            Next
                        </Button>
                    </div>
                    <DesignHeaders sendWellboreDataToParent={receiveDataFromWellboreChild} />

                </Tab>
                {selectedItems?.well_design_case_name && (
                    <Tab eventKey="site" title="Site">
                        {selectedItems?.well_design_case_name ? (
                            <>
                                <div className="text-end">
                                    <Button variant="outline-primary" onClick={handlePrevious}>
                                        Previous
                                    </Button> {' '}
                                    <Button variant="primary" onClick={handleNext}>
                                        Next
                                    </Button>
                                </div>
                                <div className="mx-4">
                                    <p>{selectedItems.well_name} surface location</p>
                                </div>
                                <HeaderSite wellId={selectedItems.well_id ? selectedItems.well_id : null} wellboreId={selectedItems.wellbore_id ? selectedItems.wellbore_id : null} />

                            </>
                        ) : (
                            <p>Please complete the header information, and create a well design case (see tab to the left of this one) before attempting to create the well site.</p>
                        )}
                    </Tab>
                )}

                {selectedItems.wellbore_id && selectedItems?.siteData && (
                    <Tab eventKey="survey" title="Survey" >
                        {selectedItems.wellbore_id && selectedItems?.siteData ? (
                            <>
                                <div className="text-end">
                                    <Button variant="outline-primary" onClick={handlePrevious}>
                                        Previous
                                    </Button> {' '}
                                    <Button variant="primary" onClick={handleNext}>
                                        Next
                                    </Button>
                                </div>
                                <HeaderSurvey companyName={dataFromWellboreChild ? dataFromWellboreChild.company_name : null} wellboreId={selectedItems.wellbore_id ? selectedItems.wellbore_id : null} wellboreName={selectedItems.wellbore_name ? selectedItems.wellbore_name : null} wellId={selectedItems.well_id ? selectedItems.well_id : null} wellName={selectedItems.wellbore_name ? selectedItems.well_name : null} />

                            </>

                        ) : (
                            <div>
                                <p>Please complete the header information, and create a wellbore and a drilling site (see tab to the left of this one) before attempting to create the well bore survey.</p>
                            </div>
                        )}
                    </Tab>
                )}

                {surveyData.length > 2 && selectedItems?.well_design_case_name && (
                    <Tab eventKey="holes" title="Casing scheme">
                        {surveyData.length > 2 ? (
                            <>
                                <div className="text-end">
                                    <Button variant="outline-primary" onClick={handlePrevious}>
                                        Previous
                                    </Button> {' '}
                                    <Button variant="primary" onClick={navigateToWellInformation}>
                                        Next
                                    </Button>
                                </div>
                                <div className="mx-4">
                                    <p>
                                        {selectedItems.wellbore_name}
                                        <br />
                                        Well design case: {selectedItems.well_design_case_name}
                                    </p>
                                </div>
                                <DesignHoleSections
                                    companyName={dataFromWellboreChild ? dataFromWellboreChild.company_name : null}
                                    wellboreId={selectedItems.wellbore_id ? selectedItems.wellbore_id : null}
                                    wellboreName={dataFromWellboreChild ? dataFromWellboreChild.wellbore_name : null}
                                    wellId={selectedItems.well_id ? selectedItems.well_id : null}
                                    wellName={dataFromWellboreChild ? dataFromWellboreChild.well_name : null}
                                />

                            </>
                        ) : (
                            <div className="mx-4">
                                <p>
                                    Please add a well bore survey before attempting to create the casing scheme.
                                </p>
                            </div>
                        )}
                    </Tab>
                )}

            </Tabs>
            {/* Modal for "Please select a default hole section" */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Action Required</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please select a default hole section!</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DesignCaseTabs;

